<template>
  <div id="app">
      <router-view :name="routerName"></router-view>
  </div>
</template>

<script>

export default {
    name: 'app',
    methods: {
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        }
    },
    data() {
        return {
            routerName: "default"
        }
    },
    created() {
        if (this._isMobile()){
            this.routerName = "h5"
        }


        console.log(this.routerName)
    }
}
</script>

<style>
#app {
}
</style>
