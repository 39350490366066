import  { provinceAndCityData }  from 'element-china-area-data'

/**
 * 地区数据删除港澳台
 *
 */
provinceAndCityData.splice(31,3);

export default {
    bilibiliUrl:"https://space.bilibili.com/544450296?spm_id_from=333.337.search-card.all.click",
    weibo2:{url:"https://weibo.com/u/7748063436",title:"卡片战斗先导者"},
    weibo1:{url:"https://weibo.com/u/7437360213",title:"BushiroadTCG"},
    readerUrl:"http://story.cf-vanguard.com.cn/",
    bilibili1:{url:"https://space.bilibili.com/686885200",title:"Bushiroad_TCG"},
    bilibili2:{url:"https://space.bilibili.com/1282140217",title:"卡片战斗先导者"},
    pages:{
        pageSize:10,
        pageSizes:[10, 15, 20, 30],
    },
    articleTypes:{
        1:{name:"新闻",type:1},
        2:{name:"活动",type:2},
        3:{name:"卡牌",type:3},
        88:{name:"每日一卡",type:88},
        99:{name:"规则",type:99},
        100:{name:"新手引导",type:100},
    },
    tournamentType:{
        "card_shop"         :{name:"店级赛",type:"card_shop"},
        /*"rebirth"           :{name:"Rebirth",type:"rebirth"},*/
        "casual"            :{name:"休闲赛",type:"casual"},
        "duel_city_tour"    :{name:"巡回赛",type:"duel_city_tour"},
        "duel_city_sp"      :{name:"特别赛",type:"duel_city_sp"},
        "duel_city_finals"  :{name:"总决赛",type:"duel_city_finals"},
    },
    productTypes:{
        1:{name:"补充包",type:1},
        2:{name:"预组",type:2},
        3:{name:"其他",type:3},
    },
    indexNav:[
        {name:"新手引导",to:"/index/article?type=100",isNewPage:false,title:""},
        //{name:"新手引导",to:"/",isNewPage:false,title:"敬请期待"},
        {name:"产品信息",to:"/index/productList",isNewPage:false,title:""},
        {name:"卡片列表",to:"/index/cardList",isNewPage:false,title:""},
        //{name:"卡片列表",to:"/",isNewPage:false,title:"敬请期待"},
        {name:"读物网站",to:"http://story.cf-vanguard.com.cn/",isNewPage:true,title:""},
        //{name:"规则",to:"/index/article?type=99",isNewPage:false,title:""},
        {name:"规则",to:"/",isNewPage:false,title:"敬请期待"},
        //{name:"推荐卡组",to:"/",isNewPage:false},
        //{name:"推荐卡组",to:"/index/deck",isNewPage:false,title:""},
        {name:"推荐卡组",to:"/",isNewPage:false,title:"敬请期待"},
        {name:"赛事活动",to:"/index/tournament",isNewPage:false,title:""},
        //{name:"赛事活动",to:"/",isNewPage:false,title:"敬请期待"},
        {name:"官方认证店",to:"/index/store",isNewPage:false,title:""},
    ],
    /**
     * 服务器相关配置
     */
    serve:{
        //baseUrl:"http://127.0.0.1:8000/",             // 本地
        //baseUrl:"https://devxzsgw.eguduo.com/",         // 开发服
        baseUrl:"https://api.cf-vanguard.com.cn/",    // 线上服
    },
    areaData:provinceAndCityData
};
